<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="ig2" label-for="paramValue" label="Название клиники">
        <b-form-input id="paramValue" required v-model="mu.clinicName" type="text" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-row class="newAttr">
        <b-col>Комментарий:</b-col>
      </b-row>
      <b-row>
        <b-col>
          <ckeditor v-model="mu.clinicComment"
                    :config="editorConfig"></ckeditor>
        </b-col>

      </b-row>

      <button type="submit" class="save_btn_common" >Сохранить</button>

    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "ClinicEdit",
  props: {
    currentMeasurementUnit: Object
  },
  data() {
    return {
      // editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      error: null,
      mu: {
        clinicId: this.currentMeasurementUnit  ? this.currentMeasurementUnit.clinicId : null,
        clinicName: this.currentMeasurementUnit  ? this.currentMeasurementUnit.clinicName : null,
        clinicComment: this.currentMeasurementUnit  ? this.currentMeasurementUnit.clinicComment : null
      }
    }
  },
  methods: {
    onSubmit() {
      let method = 'POST'
      if (this.currentMeasurementUnit) {
        method = 'PUT'
      }


      fetch(host + '/rest/clinics', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.$emit('save-success')
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })

    }
  }
}
</script>

<style scoped>
.ck-content {
  height: 200px;
}

.newAttr {
  padding-top: 1rem;
}
</style>
