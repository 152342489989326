import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import router from './router'
import store from './store/index'
import VueMask from 'v-mask'
import { VueMaskDirective } from 'v-mask'
import CKEditor from 'ckeditor4-vue';
import messagePlugin from '@/utils/message.plugin'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// import VueDndZone from 'vue-dnd-zone'
// import 'vue-dnd-zone/vue-dnd-zone.css'

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(messagePlugin)
Vue.use(VueMask)

Vue.directive('mask', VueMaskDirective);

Vue.use(CKEditor)
// Vue.use(VueDndZone)

Vue.use(VueReCaptcha, { siteKey: '6Ld17vkaAAAAAHlPMjZtESR1Qdd54FtGtwhV4fo7', //'6Les0gMbAAAAALCWpJVNsfSLXRmVpAiUStVhDC66',//
    loaderOptions: {
      autoHideBadge: true
    }
  })

router.beforeEach(async (to, from, next) => {
  console.log('beforeeach called ')
  //console.log('router to ' + JSON.stringify(to))
  if (to.matched.some(record => (record.meta.isAuth == false))) {
    await store.dispatch('checkAuth')
    console.log('store auth False isAuthorized ' + store.getters.isAuthorized)
    if (!store.getters.isAuthorized) {
      next()
    } else {
      next({ path: '/' })
    }

  } else if (to.matched.some(record => (record.meta.isAuth == true))) {
    await store.dispatch('checkAuth')
    console.log('store isAuthorized ' + store.getters.isAuthorized)
    if (store.getters.isAuthorized) {
      next()
    } else {
      next({ path: '/' })
    }
  } else {
    next()
  }
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
